import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContentFrame from "../components/ContentFrame";
import ProfilfeldLinks from "../components/ProfilfeldLinks";

function MeldungTemplate({ data }) {
  const post = data.wpPost;
  let profilfelds = [];
  let saeules = [];

  post.strapiConnect.saeule.map((string) => {
    let feld = { string: string };

    if (feld.string === "Forschung") {
      Object.assign(feld, { Titel: feld.string, Slug: "forschung" });
    }
    if (feld.string === "Lehre") {
      Object.assign(feld, { Titel: feld.string, Slug: "lehre" });
    }
    if (feld.string === "Transfer") {
      Object.assign(feld, { Titel: feld.string, Slug: "transfer" });
    }

    saeules.push(feld);
  });

  post.strapiConnect.profilfeld.map((string) => {
    let feld = { string: string };

    if (feld.string === "Digitalethik") {
      Object.assign(feld, { Titel: "Digitalethik", Slug: "digitalethik" });
    }
    if (feld.string === "Nachhaltigkeit") {
      Object.assign(feld, {
        Titel: "Nachhaltigkeit",
        Slug: "nachhaltigkeit",
      });
    }
    if (feld.string === "SustainableFinance") {
      Object.assign(feld, {
        Titel: "Sustainable Finance",
        Slug: "sustainable-finance",
      });
    }
    if (feld.string === "Wertepositionierung") {
      Object.assign(feld, {
        Titel: "Wertepositionierung",
        Slug: "wertepositionierung",
      });
    }
    if (feld.string === "CorporateGovernance") {
      Object.assign(feld, {
        Titel: "Corporate Governance",
        Slug: "corporate-governance",
      });
    }
    if (feld.string === "Sozialwirtschaft") {
      Object.assign(feld, {
        Titel: "Sozialwirtschaft",
        Slug: "sozialwirtschaft",
      });
    }

    profilfelds.push(feld);
  });

  return (
    <Layout>
      <SEO
        keywords={[
          `InnovationLab`,
          `ethik`,
          `management`,
          `aktuelles`,
          "bayreuth",
          "p&e",
        ]}
        title={post.title}
        description={post.excerpt.replace(/<[^>]*>?/gm, "").substring(0, 250)}
      />
      <ContentFrame>
        <section className="grid md:grid-cols-9 md:gap-8 gap-0 mt-12 md:mt-0 mb-12">
          <div className="col-span-3">
            {post.featuredImage && (
              <Img
                fluid={
                  post.featuredImage?.node?.localFile?.childImageSharp?.fluid
                }
              />
            )}
          </div>
          <div className="col-span-6 w-full shadow-lg p-4 md:p-8">
            <div className="break-words mx-auto">
              <p>
                <span>{post.date}</span>
              </p>
              <h1 className="font-header break-words text-2xl md:text-3xl">
                {post.title}
              </h1>
              <div className="mt-6 mb-6">
                <br />
                <p className="font-bold">
                  {profilfelds.length > 1 ? "Profilfelder" : null}
                  {profilfelds.length === 1 ? "Profilfeld" : null}
                </p>
                <ProfilfeldLinks edges={profilfelds} />
                <br />
                <p className="font-bold">
                  {saeules.length > 1 ? "Säulen" : null}
                  {saeules.length === 1 ? "Säule" : null}
                </p>
                <ProfilfeldLinks edges={saeules} />
              </div>
              <div
                className="prose"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </div>
          </div>
        </section>
      </ContentFrame>
    </Layout>
  );
}

MeldungTemplate.propTypes = {
  data: PropTypes.any,
};

export const query = graphql`
  query ($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      date(formatString: "DD.MM.YYYY")
      content
      title
      excerpt
      strapiConnect {
        profilfeld
        saeule
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default MeldungTemplate;
